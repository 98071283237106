function App() {
  return (
    <div className="App">
      <header className="App-header">
        <center>
        <h1>Em manutenção</h1>
        <p>
          O pandlr está passando por uma manutenção e voltará em breve.
        </p>
        <p>
          Status da manutenção: 90%
        </p>
        <img src="https://i.imgur.io/YeF8StU.gif" width="500" height="500" alt="Atualizei" />
        </center>
      </header>
    </div>
  );
}

export default App;
